import $ from 'jquery';
import Swiper, { Navigation, Pagination, Autoplay, Scrollbar, EffectFade, Parallax, EffectCoverflow } from 'swiper';
import 'selectric';
import 'jquery-form';
import { launchScrollingAnimations } from './main-scripts/scrollingAnimations';
import { controllerMain, windowHeight, animateScrollEffectProfi } from "./main-scripts/globals";
import 'jquery-validation';
import { showPopBox, hidePopBox } from './main-scripts/pop-blocks.js';
import './main-scripts/alfa-form';
import './main-scripts/smart-loader';
import './main-scripts/sk_scrollTo';
window.jQuery = window.$ = $;
var fancybox = require("@fancyapps/fancybox");
import smoothScrollbar from 'smooth-scrollbar';
Number.isNaN = Number.isNaN || function (value) {
  return typeof value === 'number' && isNaN(value);
};
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, EffectFade, Parallax, EffectCoverflow]);
var terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';
var $document = $(document);
$('[data-fancybox]').each(function () {
  console.log("hi???");
});
$('[data-fancybox]').fancybox({
  hash: false
});
$(' .jsWhyChooseCarousel').each(function () {
  var swiperContainer = $(this).find('.swiper-container');
  var slidesPerGroup = 2;
  if (Modernizr.device_xs) {
    slidesPerGroup = 1;
  }
  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    slidesPerGroup: slidesPerGroup,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    navigation: {
      nextEl: $(this).find('.btn-next')[0],
      prevEl: $(this).find('.btn-prev')[0]
    },
    scrollbar: {
      el: $(this).find('.swiper-scrollbar')[0]
    }
  });
});

// selectric (custom style for select) http://selectric.js.org/index.html
if ($('.js_selectCustom').length) {
  $('select.js_selectCustom').selectric({
    expandToItemText: true,
    disableOnMobile: false,
    onChange: function onChange(element) {
      $(element).closest('.selectric-custom-select').addClass('selectric-changed').find('.selectric').addClass('select-changed');
    },
    onOpen: function onOpen(element) {
      var elemScrolldiv = $(element).closest('.selectric-wrapper').find('.selectric-scroll');
      if (elemScrolldiv.length) {
        scrollerUpdate(elemScrolldiv);
      }
    }
  });
}
export function scrollerUpdate(scroller) {
  var scroll = smoothScrollbar.get(scroller[0]);
  scroll.update();
  var scrollSizes = scroll.getSize();
  if (scrollSizes.container.height < scrollSizes.content.height) {
    scroller.addClass('hasScroll');
  } else {
    scroller.removeClass('hasScroll');
  }
}
// styling for custom scroll  http://manos.malihu.gr/jquery-custom-content-scroller/
$('.selectric-scroll').each(function () {
  var fakeScroll = $(this);
  var scrollbarSlider = smoothScrollbar.init(fakeScroll[0], {
    damping: 0.07,
    continuousScrolling: false
  });
});
$('._gCustomScroll').each(function () {
  var scrollElement = $(this);
  var itemScrollbar;
  var isContinious = false;
  if ($(this).data('continium')) {
    isContinious = true;
  }
  itemScrollbar = smoothScrollbar.init(scrollElement[0], {
    continuousScrolling: isContinious
  });
  setTimeout(function () {
    scrollerUpdate(scrollElement);
  }, 300);
});
$('.no-device_xs ._gCustomScrollNOXS').each(function () {
  var scrollElement = $(this);
  var itemScrollbar;
  var isContinious = false;
  if ($(this).data('continium')) {
    isContinious = true;
  }
  itemScrollbar = smoothScrollbar.init(scrollElement[0], {
    continuousScrolling: isContinious
  });
  setTimeout(function () {
    scrollerUpdate(scrollElement);
  }, 300);
});

// mobile ONLY carousels
$('.device_xs .jsMobileCarousel').each(function () {
  var swiperContainer = $(this).find('.swiper-container');
  var mySwiper = new Swiper(swiperContainer[0], {
    // Optional parameters
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    loop: false,
    grabCursor: true,
    centeredSlides: false,
    pagination: {
      el: $(this).find('.swiper-pagination')[0]
    }
  });
});
$(".jsDemoSend").on("click", function () {
  $(this).closest(".modal").addClass("sended").find(".success-abs-box").fadeIn("fast");
});
$("#modalConsult").on('hidden.bs.modal', function (e) {
  $("#modalConsult").removeClass("sended");
});
$("#modalAbout").on('shown.bs.modal', function (e) {
  var scrollElement = $("#modalAbout ._gCustomScroll");
  setTimeout(function () {
    scrollerUpdate(scrollElement);
  }, 300);
});
$(".jsCalcForm").on("change", function () {
  var starsSelectedValue = $("#selectStars").val();
  var numbersSelectedValue = $("#selectNumbers").val();
  var pricesTableStarRow = $(".prices-table tbody tr").eq(starsSelectedValue);
  var pricesTableNumbersColText = pricesTableStarRow.find("td").eq(numbersSelectedValue).text();
  $(this).find(".price").addClass("changed").text(pricesTableNumbersColText);
});
$('.jsConsultForm').validate({
  messages: {
    name: "Введите ваше имя",
    telemail: "Введите телефон или Email"
  },
  rules: {
    name: {
      required: true,
      minlength: 3
    },
    telemail: {
      required: true,
      minlength: 6
    }
  },
  submitHandler: function submitHandler(form) {
    $(form).ajaxSubmit({
      type: "POST",
      data: $(form).serialize(),
      url: "contact-send.php",
      success: function success() {
        $("#modalConsult").addClass("sended").find(".success-abs-box").fadeIn("fast");
        setTimeout(function () {
          $("#modalConsult").modal('toggle');
        }, 2000);
      },
      error: function error() {
        console.log(" error sending mail");
      }
    });
  }
});
$('.jsConsultFormTel').validate({
  messages: {
    name: "Введите ваше имя",
    telemail: "Введите телефон"
  },
  rules: {
    name: {
      required: true,
      minlength: 3
    },
    tel: {
      required: true
    }
  },
  submitHandler: function submitHandler(form) {
    $(form).ajaxSubmit({
      type: "POST",
      data: $(form).serialize(),
      url: "contact-send-tel.php",
      success: function success() {
        $(".jsConsultFormTel").addClass("sended");
        setTimeout(function () {
          $(".jsConsultFormTel").removeClass("sended");
          $(".jsConsultFormTel .form-control").val("");
        }, 2000);
      },
      error: function error() {
        console.log(" error sending mail");
      }
    });
  }
});