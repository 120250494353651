function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { TimelineMax, TweenLite, TweenMax, Linear } from 'gsap';
import $ from 'jquery';
import gsap from "gsap";
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
export var windowWidth = window.innerWidth;
export var windowHeight = window.innerHeight;
export var controllerMain = new ScrollMagic.Controller();
export var controllerTL = new ScrollMagic.Controller({
  globalSceneOptions: {
    triggerHook: "1",
    duration: "0",
    reverse: true
  }
});
import { scrollbar, allScrollingEffectsInit } from "./scrollingAnimations";
var isResized = false;
$(window).on('resize', function () {
  windowHeight = window.innerHeight;
  if (!Modernizr.device_xs) {
    isResized = true;
    effectsController.scrollbarListener();
    setTimeout(function () {
      afterAllImagesLoadedEffectsInit();
    }, 500);
  }
});
function afterAllImagesLoadedEffectsInit() {
  console.log("afterAllEffects Init");
  allScrollingEffectsInit();
  effectsController.stopListener();
  effectsController.startListener();
}
export function afterAllPageImagesLoaded() {
  if (!Modernizr.device_xs) {
    afterAllImagesLoadedEffectsInit();
  }
}

///анимация по оси Y
export function animateScrollEffectProfi($element, pixelsToScroll, triggerPixelsFrom, duration, isDebug, isPercent) {
  //если элемент существует, тогда продолжаем
  if ($element.length && !Modernizr.device_xs && $('#scrolled').length) {
    var scrollAmountY = 0;
    var scrolledInside = 0;
    var scrolledPercents = 0;
    if (isDebug) {
      console.log("duration: " + duration);
    }
    var durationFromStartToEnd = triggerPixelsFrom + duration;
    scrollbar.addListener(function (s) {
      if (s.offset.y >= triggerPixelsFrom && s.offset.y <= durationFromStartToEnd) {
        scrolledInside = scrollbar.offset.y - triggerPixelsFrom;
        scrolledPercents = scrolledInside / duration * 100;
        scrollAmountY = pixelsToScroll * scrolledPercents / 100;
        if (isPercent === true) {
          TweenLite.set($element, {
            yPercent: scrollAmountY
          });
        } else {
          TweenLite.set($element, {
            y: scrollAmountY
          });
        }
        if (isDebug) {
          console.log('Всего пикселей проскролено: ' + s.offset.y);
          console.log('пикселей с начала блока: ' + scrolledInside);
          console.log('Процентов проскролено: ' + scrolledPercents);
          console.log('Проскролено из нужного: ' + scrollAmountY);
          console.log("ScrollAmountY: " + scrollAmountY);
          console.log("-------------: ");
        }
      }
      if (s.offset.y <= triggerPixelsFrom) {
        TweenLite.set($element, {
          y: 0
        });
      }
      if (s.offset.y >= durationFromStartToEnd) {
        TweenLite.set($element, {
          y: pixelsToScroll
        });
      }
    });
  }
}

///анимация по оси X
export function animateXScrollEffectProfi($element, pixelsToScroll, triggerPixelsFrom, duration, isDebug, isPercent) {
  //если элемент существует, тогда продолжаем
  if ($element.length && !Modernizr.device_xs && $('#scrolled').length) {
    var scrollAmountY = 0;
    var scrolledInside = 0;
    var scrolledPercents = 0;
    if (isDebug) {
      console.log("duration: " + duration);
    }
    var durationFromStartToEnd = triggerPixelsFrom + duration;
    scrollbar.addListener(function (s) {
      if (s.offset.y >= triggerPixelsFrom && s.offset.y <= durationFromStartToEnd) {
        scrolledInside = scrollbar.offset.y - triggerPixelsFrom;
        scrolledPercents = scrolledInside / duration * 100;
        scrollAmountY = pixelsToScroll * scrolledPercents / 100;
        if (isPercent === true) {
          TweenLite.set($element, {
            xPercent: scrollAmountY
          });
        } else {
          TweenLite.set($element, {
            x: scrollAmountY
          });
        }
        if (isDebug) {
          console.log('Всего пикселей проскролено: ' + s.offset.y);
          console.log('пикселей с начала блока: ' + scrolledInside);
          console.log('Процентов проскролено: ' + scrolledPercents);
          console.log('Проскролено из нужного: ' + scrollAmountY);
          console.log("ScrollAmountY: " + scrollAmountY);
          console.log("-------------: ");
        }
      }
      if (s.offset.y <= triggerPixelsFrom) {
        TweenLite.set($element, {
          x: 0
        });
      }
      if (s.offset.y >= durationFromStartToEnd) {
        TweenLite.set($element, {
          x: pixelsToScroll
        });
      }
    });
  }
}

//АНИМАЦИЯ ПРОЗРАЧНОСТИ
export function animateScaleEffectProfi($element, fromScale, toScale, triggerPixelsFrom, duration, isDebug, additionDuration) {
  //если элемент существует, тогда продолжаем
  if ($element.length) {
    var elementOffsetTop = triggerPixelsFrom;
    var scaleAmount = fromScale - toScale;
    var currentScale = fromScale;
    var scrolledInside = 0;
    var scrolledPercents = 0;
    var durationFromStartToEnd = elementOffsetTop + duration;
    scrollbar.addListener(function (s) {
      if (s.offset.y >= elementOffsetTop && s.offset.y <= durationFromStartToEnd) {
        scrolledInside = scrollbar.offset.y - triggerPixelsFrom;
        scrolledPercents = scrolledInside / duration * 100;
        currentScale = fromScale - scrolledPercents * scaleAmount / 100;
        TweenLite.set($element, {
          scale: currentScale
        });
        if (isDebug) {
          console.log('Всего пикселей проскролено: ' + s.offset.y);
          console.log('пикселей с начала блока: ' + scrolledInside);
          console.log('Процентов проскролено: ' + scrolledPercents);
          console.log("FromScale: " + fromScale);
          console.log("ToScale: " + toScale);
          console.log("ScaleAmount: " + currentScale);
          console.log("---------");
        }
      }
      if (s.offset.y <= triggerPixelsFrom) {
        TweenLite.set($element, {
          scale: fromScale
        });
      }
      if (s.offset.y >= durationFromStartToEnd) {
        TweenLite.set($element, {
          scale: toScale
        });
      }
    });
  }
}
export var effectsController = null;
effectsController = {
  items: [],
  addItem: function addItem(itemToAdd) {
    itemToAdd.triggerEnd = itemToAdd.triggerStart + itemToAdd.duration;
    if (itemToAdd.scaleFrom) {
      itemToAdd.scaleAmount = itemToAdd.scaleFrom - itemToAdd.scaleTo;
      itemToAdd.currentScale = itemToAdd.scaleFrom;
    }
    var _iterator = _createForOfIteratorHelper(this.items),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var item = _step.value;
        if (item.id == itemToAdd.id) {
          //если есть то просто обновляем данные в нем
          item.triggerStart = itemToAdd.triggerStart;
          item.duration = itemToAdd.duration;
          item.triggerEnd = itemToAdd.triggerEnd;
          item.translateY = itemToAdd.translateY;
          return;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    this.items.push(itemToAdd);
  },
  scrollbarListener: function scrollbarListener(s) {
    var _iterator2 = _createForOfIteratorHelper(effectsController.items),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var item = _step2.value;
        if (scrollbar.offset.y >= item.triggerStart && scrollbar.offset.y <= item.triggerEnd) {
          var scrolledPercents = (scrollbar.offset.y - item.triggerStart) / item.duration * 100;
          var scrollAmountY = item.translateY * scrolledPercents / 100;
          if (item.scaleAmount) {
            item.currentScale = item.scaleFrom - scrolledPercents * item.scaleAmount / 100;
            gsap.set(item.element, {
              y: scrollAmountY,
              scale: item.currentScale
            });
          } else {
            gsap.set(item.element, {
              y: scrollAmountY
            });
          }
        }
        if (scrollbar.offset.y <= item.triggerStart) {
          if (item.scaleAmount) {
            gsap.set(item.element, {
              y: 0,
              scale: item.scaleFrom
            });
          } else {
            gsap.set(item.element, {
              y: 0
            });
          }
        }
        if (scrollbar.offset.y >= item.triggerEnd) {
          if (item.scaleAmount) {
            gsap.set(item.element, {
              y: item.translateY,
              scale: item.scaleTo
            });
          } else {
            gsap.set(item.element, {
              y: item.translateY
            });
          }
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  },
  startListener: function startListener() {
    scrollbar.addListener(this.scrollbarListener);
  },
  stopListener: function stopListener() {
    scrollbar.removeListener(this.scrollbarListener);
  }
};

//ios full height.
if (Modernizr.device_xs) {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');

  // YOUR XS CODE HERE...
}