import $ from 'jquery';
import { scrollerUpdate } from './../main';
// small-modal-popup-script NEW VERSION!!!! NEW !!!!  NEW FROM 23OCT.
var currentOpenedModal;
var isModalOpened = false;
var linkOpener;
$(".pop-block").click(function (event) {
  event.stopPropagation();
});
$('.pop-block *[data-toggle="modal"]').on("click", function () {
  var targetModal = $(this).data("target");
  hidePopBox(currentOpenedModal);
  $(targetModal).modal('show');
});
function onClickedBtnBlock(clickedButton) {
  var target = clickedButton.data("target");
  linkOpener = clickedButton;
  var isAlone = false;
  if (clickedButton.data('alone')) isAlone = true;
  if (isAlone && currentOpenedModal) {
    var currentModalID = '#' + currentOpenedModal.attr("id");
    var targetModalID = clickedButton.data("target");
    if (currentModalID != targetModalID) {
      $('*[data-toggle="block"]').removeClass("btn-actived");
      hidePopBox(currentOpenedModal);
      linkOpener = clickedButton;
    }
  }
  currentOpenedModal = $(" " + target);
  if (!isModalOpened) {
    showPopBox(currentOpenedModal);
  } else {
    hidePopBox(currentOpenedModal);
  }
}
$('*[data-toggle="block"]').click(function (event) {
  event.stopPropagation();
  onClickedBtnBlock($(this));
});
export function showPopBox(modalToOpen) {
  if (isModalOpened == false) {
    currentOpenedModal = modalToOpen;
    currentOpenedModal.fadeIn("fast").addClass("opened");
    linkOpener.addClass('btn-actived');
    // $(".js_CustomScrollbar").mCustomScrollbar("update");
    // $(".js_CustomScrollbarDrop").mCustomScrollbar("update");
    if (currentOpenedModal.attr("id") == "siteMenu") {
      $("html").addClass("siteMenuOpened");
    }
    setTimeout(function () {
      currentOpenedModal.find("._gCustomScroll").each(function () {
        scrollerUpdate($(this));
      });
    }, 200);
  }
  isModalOpened = true;
}
export function hidePopBox(modalToHide) {
  modalToHide.fadeOut("fast").removeClass("opened");
  linkOpener.removeClass('btn-actived');
  if (currentOpenedModal.attr("id") == "siteMenu") {
    $("html").removeClass("siteMenuOpened");
  }
  linkOpener = null;
  currentOpenedModal = null;
  isModalOpened = false;
}

// close button block
$('*[data-dismiss="block"]').click(function (event) {
  hidePopBox(currentOpenedModal);
});
$('*[data-dismiss-current="block"]').click(function (event) {
  currentOpenedModal = $(this).closest(".pop-block");
  linkOpener = $(this);
  hidePopBox(currentOpenedModal);
});
$("html").on('click', function (event) {
  if (isModalOpened == true) {
    hidePopBox(currentOpenedModal);
  }
});