import { controllerMain, windowWidth, windowHeight, animateScrollEffectProfi, animateScaleEffectProfi, animateXScrollEffectProfi, effectsController } from './globals';
import gsap from "gsap";
import { TweenMax, TimelineLite, Power1, Linear, Circ } from 'gsap';
import SplitText from './../vendors/SplitText.min';
import $ from 'jquery';
import smoothScrollbar from 'smooth-scrollbar';
import ScrollMagic from 'scrollmagic';
export var scrollbar;
gsap.config({
  nullTargetWarn: false
});
export function allScrollingEffectsInit() {
  var itemId = 1;
  //фиксированные блоки
  $("*[data-skarallax-fix]").each(function (index, el) {
    var additionDuration = 0;
    var $element = $(this);
    if ($element.data("add-duration")) {
      additionDuration = parseInt($element.data("add-duration"));
    }
    var $trigger = $element.closest(".jsFixedWrapper");
    if ($element.data('trigger')) {
      $trigger = $($element.data('trigger'));
    }
    var triggerOffsetTop = $trigger.offset().top + scrollbar.offset.y - windowHeight;
    if (triggerOffsetTop < 0) {
      triggerOffsetTop = 0;
    }
    effectsController.addItem({
      id: "itemFirst" + itemId,
      element: $element,
      triggerStart: triggerOffsetTop,
      duration: $trigger.outerHeight() + additionDuration,
      translateY: $trigger.outerHeight()
    });
    itemId++;
  });
  $("*[data-skarallax-scale]").each(function (index, el) {
    //console.log($(this).offset().top)
    var thisOffsetTop = $(this).offset().top + scrollbar.offset.y;
    var readyObject = {};
    readyObject.id = "item" + itemId;
    readyObject.element = $(this).find("picture");
    readyObject.triggerStart = thisOffsetTop - windowHeight;
    readyObject.duration = $(this).outerHeight() + windowHeight;
    if ($(this).data("skarallax")) {
      readyObject.duration += parseInt($(this).data("skarallax"));
    }
    readyObject.scaleTo = parseFloat($(this).data("skarallax-scale"));
    readyObject.scaleFrom = parseFloat($(this).data("scale-from"));
    readyObject.translateY = $(this).outerHeight();
    effectsController.addItem(readyObject);
    itemId++;
  });
  $("*[data-skarallax]").each(function (index, el) {
    var $element = $(this);
    var elementOffsetTop = $element.offset().top + scrollbar.offset.y;
    var wantedToScroll = parseInt($element.data("skarallax"));
    var duration = windowHeight + $element.outerHeight() + wantedToScroll; //is debug
    if ($(this).data("percent")) {
      var percent = $element.data("percent");
      wantedToScroll = $element.outerHeight() * percent / 100;
    }
    effectsController.addItem({
      id: "item" + itemId,
      element: $element,
      triggerStart: elementOffsetTop - windowHeight,
      duration: duration,
      translateY: wantedToScroll
    });
    itemId++;
  });
  $(".top-screen-section").each(function () {
    var $element = $(this);
    var triggerPixelsFrom = $(".top-screen-section ").offset().top + scrollbar.offset.y;
    var duration = $(".top-screen-section").outerHeight();
    effectsController.addItem({
      id: "itemFirst" + itemId,
      element: $element.find(".sect-bg"),
      triggerStart: 0,
      duration: duration,
      translateY: $(".top-screen-section").outerHeight() / 1.1
    });
    itemId++;
    effectsController.addItem({
      id: "itemFirst" + itemId,
      element: $element.find(".sect-content-box"),
      triggerStart: 0,
      duration: duration,
      translateY: $(".top-screen-section").outerHeight() / 2
    });
    itemId++;
  });
}
export function launchScrollingAnimations() {
  console.log("scrolling animations started");
  if (!Modernizr.device_xs && $('#scrolled').length) {
    smoothScrollbar.destroy(document.getElementById('horiz-scrollbar'));
    var overscrollOptions = {
      enable: true,
      effect: navigator.userAgent.match(/Android/) ? 'glow' : 'bounce',
      damping: 0.2,
      maxOverscroll: 150,
      glowColor: '#222a2d'
    };
    scrollbar = smoothScrollbar.init(document.getElementById('scrolled'), {
      damping: 0.07
    });
  }
  var follower;
  if (!Modernizr.device_xs) {
    follower = $(".fake-cursor");
    var posX = 0,
      posY = 0;
    var mouseX = 0,
      mouseY = 0;
    gsap.to({}, 0.01, {
      repeat: -1,
      onRepeat: function onRepeat() {
        posX += (mouseX - posX) / 9;
        posY += (mouseY - posY) / 9;
        gsap.set(follower, {
          css: {
            x: posX - 12,
            y: posY - 12
          }
        });
      }
    });
    $(document).on("mousemove", function (e) {
      mouseX = e.pageX;
      mouseY = e.pageY;
    });
  }

  // fake cursor hovers
  $(".no-device_xs").on("mouseenter", '.why-choose-carousel', function () {
    follower.addClass("can-drag");
  });
  $(".no-device_xs").on("mouseleave", '.why-choose-carousel', function () {
    follower.removeClass("can-drag");
  });
  $(".scrolled-area-box").each(function () {
    var $element = $(this);
    var $elementToScrollY = $(this).find(".scroller-y");
    var wantedToScrollY = $element.outerHeight();
    var $elementToScrollX = $(this).find(".scrolled-x");
    var wantedToScrollX = $(this).find(".scrolled-x").width() - windowWidth;
    var $titlesToScroll = $(this).find(".sect-top");
    var $textToScroll = $(this).find(".scrolled-text");
    var triggerPixelsFrom = $element.offset().top;
    var duration = $element.outerHeight();
    var durationX = $element.outerHeight() - windowHeight;
    animateXScrollEffectProfi($elementToScrollX, -wantedToScrollX, triggerPixelsFrom, durationX, false, false);
    animateXScrollEffectProfi($titlesToScroll, wantedToScrollX, triggerPixelsFrom, durationX, false, false);
    animateXScrollEffectProfi($textToScroll, wantedToScrollX / 1.5, triggerPixelsFrom, durationX, false, false);
    animateScrollEffectProfi($elementToScrollY, wantedToScrollY, triggerPixelsFrom, duration, false, false);
  });
  $("header").each(function () {
    var $element = $(this);
    var $elementToScroll = $(this);
    var wantedToScroll = $element.outerHeight() + windowHeight / 20;
    var triggerPixelsFrom = 0;
    var duration = windowHeight / 3;
    animateScrollEffectProfi($elementToScroll, -wantedToScroll, triggerPixelsFrom, duration, false, false);
    scrollbar.addListener(function (s) {
      if (scrollbar.offset.y > windowHeight) {
        $element.addClass("actived");
      } else {
        $element.removeClass("actived");
      }
    });
  });
  $(".work-feat-section").each(function () {
    var $element = $(this);
    var $elementToScroll = $element.find(".sectbg");
    var wantedToScroll = $element.outerHeight();
    var triggerPixelsFrom = $element.offset().top - windowHeight;
    var duration = $element.outerHeight() + windowHeight;
    animateScrollEffectProfi($elementToScroll, wantedToScroll, triggerPixelsFrom, duration, false, false);
    var $element2 = $(".scrolled-area-box");
    var $elementToScroll2 = $element2;
    var wantedToScroll2 = -200;
    var triggerPixelsFrom2 = $element.offset().top - windowHeight;
    var duration2 = windowHeight;
    // animateScrollEffectProfi($elementToScroll2, wantedToScroll2, triggerPixelsFrom, duration2, false, false)
  });
  $(".about-service-sect .picture-round").each(function () {
    var $element = $(this);
    var $elementToScroll = $element.find("picture");
    var fromScale = 1;
    var toScale = 1.3;
    var triggerPixelsFrom = $element.offset().top - windowHeight;
    var duration = $element.outerHeight() + windowHeight;
    animateScaleEffectProfi($elementToScroll, fromScale, toScale, triggerPixelsFrom, duration, false);
  });
  $(".jsDataSister").each(function () {
    var $element = $(this);
    var $elementToScroll = $element;
    var wantedToScroll = windowWidth / 8;
    var triggerPixelsFrom = $element.find("picture").offset().top - windowHeight;
    var duration = windowHeight + $element.outerHeight() + wantedToScroll;
    animateScrollEffectProfi($elementToScroll, wantedToScroll, triggerPixelsFrom, duration, false, false);
  });
  $(".jsScrollSpy").each(function () {
    console.log("trig");
    $(this).find("a").each(function () {
      var thisLink = $(this);
      var thisHref = $(this).attr("href");
      var targetSect = $(thisHref);
      var targetSectTriggerStart = targetSect.offset().top - $(".header-holder").outerHeight() - 2;
      var targetTriggerEnd = targetSect.offset().top + targetSect.outerHeight();
      scrollbar.addListener(function (s) {
        if (scrollbar.offset.y > targetSectTriggerStart && scrollbar.offset.y < targetTriggerEnd) {
          thisLink.addClass("actived");
          thisLink.closest("li").siblings().find("a").removeClass("actived");
        } else {
          thisLink.removeClass("actived");
        }
      });
    });
  });
  var animDuration_5 = $(".top-screen-section").outerHeight();
  var animScene_5 = new ScrollMagic.Scene({
    triggerElement: ".top-screen-section",
    triggerHook: 0,
    duration: animDuration_5
  }).setTween(".no-device_xs .top-screen-section .section-holder", 1, {
    opacity: 0,
    ease: Linear.easeNone
  }).addTo(controllerMain);
  scrollbar.addListener(function (s) {
    animScene_5.refresh();
  });
}
export function launchAnimations() {
  $(".top-screen-section").each(function () {
    //первый текст  Сплит
    var thisTimeLine = new TimelineLite();
    var thisSplitText = new SplitText('.top-screen-section  h1', {
      type: "lines"
    });
    thisTimeLine.staggerFrom(thisSplitText.lines, 0.6, {
      y: 50,
      autoAlpha: 0,
      delay: 0.2
    }, 0.1);
    thisTimeLine.to('.top-screen-section  .subtitle', 0.5, {
      opacity: 1,
      y: 0,
      ease: Power1.easeInOut
    }, 0.7);
    thisTimeLine.to('.top-screen-section  ._g-red-arrow-btn', 0.5, {
      opacity: 1,
      ease: Power1.easeInOut
    }, 0.9);
    thisTimeLine.to('.top-screen-section  .star-image img', 1, {
      opacity: 1,
      scale: 1,
      ease: Power1.easeInOut
    }, 0.8);
    thisTimeLine.to('.top-screen-section  .right-col', 0.7, {
      opacity: 1,
      x: 0,
      ease: Power1.easeInOut
    }, 1.3);
    thisTimeLine.to('.top-screen-section  .mouse-link', 0.5, {
      opacity: 1,
      ease: Power1.easeInOut
    }, 1.3);
  });
}
if (Modernizr.device_xs) {
  var mobileMenuOpened = false;
  var xsHeaderTimeLine = gsap.timeline();
  xsHeaderTimeLine.staggerFrom(".main-nav li", 0.5, {
    opacity: 0,
    y: -20,
    ease: Power1.easeInOut
  }, 0.08, 0);
  xsHeaderTimeLine.from(".mobile-menu .menu-bottom .adr-row", 0.8, {
    opacity: 0,
    y: 20,
    ease: Power1.easeInOut
  }, 0.6);
  xsHeaderTimeLine.from(".mobile-menu .menu-bottom .mail-row", 0.8, {
    opacity: 0,
    y: -20,
    ease: Power1.easeInOut
  }, 0.6);
  xsHeaderTimeLine.paused(true);
  $(".js_mobileBtn").on("click", function () {
    if (mobileMenuOpened == false) {
      $(this).addClass("btn-actived");
      $("header .menu-column").slideDown("fast", function () {
        $("header").addClass("menu-opened");
        xsHeaderTimeLine.timeScale(1);
        xsHeaderTimeLine.play();
      });
      mobileMenuOpened = true;
    } else {
      $(this).removeClass("btn-actived");
      xsHeaderTimeLine.timeScale(3);
      xsHeaderTimeLine.reverse();
      xsHeaderTimeLine.eventCallback("onReverseComplete", function () {
        $("header .menu-column").slideUp("fast");
        $("header").removeClass("menu-opened");
      });
      mobileMenuOpened = false;
    }
  });
  $(".jsScrollSpy a").on("click", function () {
    $(".js_mobileBtn").removeClass("btn-actived");
    xsHeaderTimeLine.timeScale(3);
    xsHeaderTimeLine.reverse();
    xsHeaderTimeLine.eventCallback("onReverseComplete", function () {
      $("header .menu-column").slideUp("fast");
      $("header").removeClass("menu-opened");
    });
    mobileMenuOpened = false;
  });
}