import $ from 'jquery';
import { launchScrollingAnimations, launchAnimations } from './scrollingAnimations';
import { afterAllPageImagesLoaded } from './globals';
var loaderDebug = false;
//if we load page with ajax link
var ajaxed = false;
var imagesLoaded = require('imagesloaded');
if (!ajaxed) {
  var pageWrapper = $(".all-page-wrapper");
  if (loaderDebug) console.log("Started from Page ,  Loaded Dom..");
  formatePage(pageWrapper);
}
var LoadingBar = $(".page-preloader .js_loadingFill");
var LoadingPercents = $(".js_loadingPercents");
function updateLoader(percentsLoaded) {
  var remainPercents = 100 - percentsLoaded;
  TweenLite.to(LoadingBar, 0.5, {
    width: percentsLoaded + "%",
    ease: Linear.easeNone
  });
  LoadingPercents.text(percentsLoaded);
}

//Вначале определяем то, что будет загружаться, и потом если загрузилось - показываем страницу

//load url from link with ajax and check if all images or someshit loaded then launch
function getAjaxPage(addressValue) {
  if (loaderDebug) {
    console.log("Загружаю...");
    console.log(addressValue);
  }
  $.ajax({
    url: addressValue,
    type: 'GET',
    xhr: function xhr() {
      // get the native XmlHttpRequest object
      var xhr = $.ajaxSettings.xhr();
      // set the onprogress event handler
      // set the onload event handler
      xhr.onload = function (res) {};
      // return the customized object
      return xhr;
    },
    success: function success(res) {
      var data = $.parseHTML(res);
      var modalData = $(data).find('.all-modals-wrapper');
      data = $(data).find('.all-page-wrapper');
      formatePage(data, modalData);
    }
  });
}
function formatePage(pageData, modalData) {
  var imagesArray = [];
  var withVideo = false;
  var picturesLoaded = false;
  var videoLoaded = false;
  var withSliderVideos = false;
  if (ajaxed) {
    $('.all-page-wrapper').replaceWith(pageData);
    $('.all-modals-wrapper').replaceWith(modalData);
  }
  $(".jsPreload").each(function () {
    var preloadContainer = $(this);
    // work with picture RETINA
    preloadContainer.find("picture").each(function () {
      var imageToLoad;
      var image_src;
      var image_srcset = $(this).find("source").attr("srcset");
      var isPictureWithXS = $(this).find('img').length;
      if (Modernizr.retina) {
        var lastsrcset = image_srcset.split(',');
        var lastsrc = lastsrcset[lastsrcset.length - 1];
        var lastpuresrc = lastsrc.split(' ');
        image_src = lastpuresrc[1];
      }
      if (Modernizr.device_xs && isPictureWithXS) {
        //image_src = $(this).find("img").attr("srcset")
      } else {
        image_src = image_srcset.split(',')[0];
      }
      var img = new Image();
      img.src = image_src;
      imageToLoad = img;
      imagesArray.push(imageToLoad);
    });
    preloadContainer.find("img").each(function () {
      if (!$(this).closest("picture").length) {
        var image_src = $(this).attr("src");
        var imageToLoad;
        if (image_src) {
          // console.log(image_src)
          var img = new Image();
          img.src = image_src;
          imageToLoad = img;
          imagesArray.push(imageToLoad);
        }
      }
    });

    //если нужно сначала загрузить видео!
    preloadContainer.find(".jsLoadVideo").each(function (index, videoElement) {
      withVideo = true;
      var CheckVideoDownLoadTimer = setInterval(alertFunc, 300);
      function alertFunc() {
        if (videoElement.readyState >= 4) {
          clearInterval(CheckVideoDownLoadTimer);
          videoLoaded = true;
          loaderControllerState();
        }
      }
    });
  });
  var imgLoad = imagesLoaded(imagesArray);
  imgLoad.on('progress', function (instanse, image) {
    var result = image.isLoaded ? 'loaded' : 'broken';
    //console.log( 'image is ' + result + ' for ' + image.img.src );
  });
  imgLoad.on('done', function () {
    $(".jsWithPreloadPicture").addClass("loadedPicture");
    console.log("images loaded");
    picturesLoaded = true;
    loaderControllerState();
  });
  function loaderControllerState() {
    if (!withVideo) {
      console.log("without video  start");
      launch();
    }
    if (videoLoaded === true && picturesLoaded === true) {
      console.log("video and pictures loaded,  start!");
      launch();
    }
  }
}
function launch() {
  setTimeout(function () {
    if (!Modernizr.device_xs) {
      launchScrollingAnimations();
      loadAllImages();
    }
    setTimeout(function () {
      launchAnimations();
    }, 200);
    $("body").addClass("loaded-page");
  }, 500);
}
function loadAllImages() {
  var imagesArray = [];
  var preloadContainerAll = $('.all-page-wrapper');
  preloadContainerAll.find("picture").each(function () {
    var imageToLoad;
    var image_src;
    if (!Modernizr.device_xs) {
      var image_srcset = $(this).find("source").attr("srcset");
      if (Modernizr.retina) {
        var lastsrcset = image_srcset.split(',');
        var lastsrc = lastsrcset[lastsrcset.length - 1];
        var lastpuresrc = lastsrc.split(' ');
        image_src = lastpuresrc[1];
      } else {
        image_src = image_srcset.split(',')[0];
        //console.log(image_src)
      }
    } else {
      image_src = $(this).find("img").attr("srcset");
    }
    var img = new Image();
    img.src = image_src;
    imageToLoad = img;
    imagesArray.push(imageToLoad);
  });
  preloadContainerAll.find("img").each(function () {
    var image_src = $(this).attr("src");
    var imageToLoad;
    if (image_src) {
      //console.log(image_src)
      var img = new Image();
      img.src = image_src;
      imageToLoad = img;
      imagesArray.push(imageToLoad);
    }
  });
  var imgLoadAll = imagesLoaded(imagesArray);
  imgLoadAll.on('progress', function (instanse, image) {
    var result = image.isLoaded ? 'loaded' : 'broken';
    //console.log( 'image is ' + result + ' for ' + image.img.src );
  });
  imgLoadAll.on('always', function () {
    $(".jsWithPreloadPicture").addClass("loadedPicture");
    //console.log("images loaded");
    afterAllPageImagesLoaded();
  });
}

// клик на ссылке которая Ajax
$("body").on('click', '.js_LinkAjax', function (event) {
  var ahref = $(this).attr("href");
  var title = $(this).attr("title");
  event.preventDefault();
  $("header .main-nav .current-menu-item").removeClass("current-menu-item");
  linkControllerOne(ahref, title);
});
function linkControllerOne(ahref, title) {
  var speed = 0.6;
  $('body').removeClass("loaded-page");
  ajaxed = true;
  $('.page-preloader').addClass("isAjaxLoading");
  TweenMax.to('.page-preloader', 1, {
    height: "100%",
    ease: Circ.easeInOut
  });
  setTimeout(function () {
    $(".menu-nav li").removeClass("active current-menu-item");
    $('.menu-nav a[href="' + ahref + '"]').closest("li").addClass("active");
    getAjaxPage(ahref);
    history.pushState(null, null, ahref);
    document.title = title;
    $(window).scrollTop(0);
  }, 1000);
}

//reload page after BLACK OR FORWARD  btn in browser clicked
$(window).on("popstate", function (e) {
  location.reload();
});