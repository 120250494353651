import $ from 'jquery';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { scrollbar } from './scrollingAnimations';
import { windowHeight } from './globals';
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
$('.js_scrollToLink').click(function (e) {
  e.preventDefault();
  var addNumber = 0;
  var speed = 1500;
  if ($(this).data("speed")) {
    speed = $(this).data("speed");
  }
  if ($(this).data("first")) {
    addNumber = $(".header-holder").outerHeight() + 2;
  }
  if (!Modernizr.device_xs && $("#scrolled").length) {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      if ($('#scrolled').length) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        var scrolledAmount = parseInt(target.offset().top) - $(".header-holder").outerHeight() + addNumber;
        var scrolledDiff = scrolledAmount + parseInt(scrollbar.offset.y);
        if (target.length) {
          scrollbar.scrollTo(0, scrolledDiff, speed, false);
        }
      }
      return false;
    }
  } else {
    e.preventDefault();
    gsap.to(window, {
      duration: speed / 1000,
      scrollTo: this.hash
    });
  }
});